<template>
  <v-container fluid :class="{'pa-0': !isTab}">
    <v-row dense>
      <v-col>
        <v-card flat>
          <h2>The p1440 Point System</h2>
          <p>
            Points are awarded for all participating p1440 sanctioned tournaments.
            The amount of points awarded at each event will be determined by multiplying our
            "Base Point Scale" with any approved multipliers for the event. For any
            event without an approved multiplier the base points will be multipled by the
            "Team Participation Multiplier" detailed below.
          </p>
          <h2>Ranking Points</h2>
          <p>
            Ranking = total of best 5 point finishes over the past 365 days (regardless of division).
          </p>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card class="text-center" :flat="!isTab">
          <v-toolbar dense color="color1 color1Text--text" v-if="isTab">
            <v-toolbar-title>Base Point Scale</v-toolbar-title>
          </v-toolbar>
          <loading-bar :value="loading1"></loading-bar>
          <v-expand-transition>
            <v-card-text :class="{'pa-0': !isTab}" v-if="!loading1">
              <v-row dense>
                <v-col cols="12" sm="6" v-for="table in tables" :key="table.title">
                  <v-card>
                    <v-toolbar dense color="color2 color2Text--text">
                      <v-toolbar-title>{{table.title}}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="pa-1">
                      <v-data-table
                        :headers="table.headers"
                        :items="table.rows"
                        :options.sync="pager"
                        hide-default-footer
                        dense
                        :mobile-breakpoint="0"
                      >
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="text-center">
          <v-toolbar dense color="color1 color1Text--text">
            <v-toolbar-title>Team Participation Multiplier</v-toolbar-title>
          </v-toolbar>
          <loading-bar :value="loading2"></loading-bar>
          <v-expand-transition>
            <v-card-text v-if="!loading2">
              <v-data-table
                :headers="mHeaders"
                :items="multipliers"
                hide-default-footer
                dense
                :mobile-breakpoint="0"
              >
                <v-progress-linear slot="progress" color="color3" indeterminate></v-progress-linear>
                <template slot="items" slot-scope="props">
                  <tr>
                    <td class="text-left">{{props.item.description}}</td>
                    <td class="text-left">{{props.item.multiplier}}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { ordinal } from '@/Filters'
export default {
  props: ['isTab'],
  data () {
    return {
      loading1: false,
      loading2: false,
      pointScale: null,
      multipliers: [],
      tableData: [],
      pager: { sortBy: ['points'], itemsPerPage: -1 },
      mHeaders: [
        { text: 'Teams', align: 'left', sortable: false, value: 'description' },
        { text: 'Point Multiplier', align: 'left', sortable: false, value: 'multiplier' }
      ]
    }
  },
  computed: {
    tables () {
      return this.tableData.map(t => {
        const headers = t.headers
        headers.push('Team Points')
        return {
          title: t.title,
          headers: headers.map((h, i) => {
            return {
              text: h,
              align: 'center',
              sortable: false,
              value: `${i}`
            }
          }),
          rows: t.rows.map(r => {
            const a = {}
            const l = r.length - 1
            r.forEach((m, i) => {
              a[`${i}`] = i === l || m < 0 ? `${m}` : m ? `${ordinal(m)}` : ''
            })
            return a
          })
        }
      })
    },
    loading () {
      return this.loading1 || this.loading2
    }
  },
  methods: {
    getScale () {
      if (this.loading1) return
      this.loading1 = true
      this.$VBL.getPointScale('p1440')
        .then((response) => {
          this.tableData = response.data
          this.loading1 = false
        })
        .catch((error) => {
          console.log(error)
          this.loading1 = false
        })
    },
    getMultipliers () {
      if (this.loading2) return
      this.loading2 = true
      this.$VBL.getMultipliers('p1440')
        .then((response) => {
          this.multipliers = response.data
          this.loading2 = false
        })
        .catch((error) => {
          console.log(error)
          this.loading2 = false
        })
    }
  },
  created () {
    console.log('p1440')
    this.getScale()
    this.getMultipliers()
  }
}
</script>

<style>

</style>
